<template>
  <div>
    <Back />
    <p :class="$style.head">长按保存图片或分享给好友</p>
    <img v-if="showPoster" :src="canvasUrl" :class="$style.canvas" />
    <div v-else :class="$style.content" ref="poster">
      <img :class="$style.banner" :src="info.ddc_image_url || info.image_url" />
      <!-- <img
        :class="$style.banner"
        src="https://img2.baidu.com/it/u=436635185,1433075744&fm=253&fmt=auto&app=138&f=JPEG?w=666&h=500"
      /> -->
      <p :class="$style.title">{{ info.ddc_name || info.name }}</p>
      <p :class="$style.name">艺术家：{{ info.artist_name }}</p>
      <div :class="$style.line"></div>
      <div :class="$style.qrcodeWrap">
        <div ref="qrcode" :class="$style.qrcode"></div>
      </div>
      <p :class="$style.ownerWrap">
        <span :class="$style.owner">拥有者：{{ owner }}</span>
        <img :class="$style.logo" src="@/assets/img/logo.png" />
      </p>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { getStorage } from "@/utils/util";

export default {
  components: {
    Back,
  },
  data() {
    return {
      info: JSON.parse(this.$route.query.info),
      path: this.$route.query.path, // spin国家纺织中心作品详情，cloth艺术家作品详情
      owner: getStorage("user_info").nickname,
      showPoster: false,
      canvasUrl: "",
    };
  },
  mounted() {
    this.makeQrcode();
  },
  methods: {
    makeQrcode() {
      const urlMap = {
        spin: `/spin/detail/${this.info.ddc_id}`,
        cloth: `/cloth/detail/${this.info.ddc_id}`,
      };
      const url = location.protocol + "//" + location.host + urlMap[this.path];
      new QRCode(this.$refs.qrcode, {
        text: url, // 扫码后页面地址
        width: 68, // 二维码宽度
        height: 68, // 二维码高度
        colorDark: "#A1D8F8", // 二维码颜色
        colorLight: "#212121", // 背景颜色
        correctLevel: QRCode.CorrectLevel.H, // 校正水准
      });
      this.createPoster();
    },
    createPoster() {
      new html2canvas(this.$refs.poster, {
        allowTaint: true,
        useCORS: true,
        backgroundColor: "#212121",
      }).then((canvas) => {
        this.canvasUrl = canvas.toDataURL();
        this.showPoster = true;
      });
    },
  },
};
</script>

<style lang="scss" module>
.head {
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(90deg, #e9b0ef 50%, #5ffcff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content {
  margin: 10px auto 0;
  width: 318px;
  padding: 31px 27px 15px;
  box-sizing: border-box;
  background-color: #212121;
  font-weight: 500;
  line-height: 24px;
  .banner {
    width: 264px;
    height: 264px;
    border-radius: 12px;
  }
  .title {
    font-size: 20px;
    margin: 22px 0 5px;
  }
  .name {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 17px;
  }
  .line {
    border-bottom: 2px dashed #3e3e3e;
    margin: 17px 0 23px;
  }
  .qrcodeWrap {
    width: 81px;
    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("~@/assets/img/qrcode_bg.png");
    background-size: 100% 100%;
    .qrcode {
      width: 68px;
      height: 68px;
      img {
        width: 68px;
        height: 68px;
      }
      canvas {
        width: 68px !important;
        height: 68px !important;
      }
    }
  }
  .ownerWrap {
    height: 30px;
    display: flex;
    justify-content: space-between;
    .owner {
      align-self: flex-end;
      height: 24px;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
    }
    .logo {
      align-self: flex-start;
      width: 65px;
      height: 23px;
    }
  }
}
.canvas {
  width: 318px;
  margin: 10px auto 0;
  box-sizing: border-box;
  background: #fff;
  display: block;
}
</style>
