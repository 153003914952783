// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/qrcode_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".index_head_Sp1m2{line-height:1.06667rem;text-align:center;font-size:.42667rem;font-weight:600;background:linear-gradient(90deg,#e9b0ef 50%,#5ffcff);-webkit-background-clip:text;-webkit-text-fill-color:transparent}.index_content_1asG7{margin:.26667rem auto 0;width:8.48rem;padding:.82667rem .72rem .4rem;box-sizing:border-box;background-color:#212121;font-weight:500;line-height:.64rem}.index_content_1asG7 .index_banner_2ll9U{width:7.04rem;height:7.04rem;border-radius:.32rem}.index_content_1asG7 .index_title_3KQQF{font-size:.53333rem;margin:.58667rem 0 .13333rem}.index_content_1asG7 .index_name_2sqjm{font-weight:500;font-size:.32rem;margin-bottom:.45333rem}.index_content_1asG7 .index_line_22ZOy{border-bottom:.05333rem dashed #3e3e3e;margin:.45333rem 0 .61333rem}.index_content_1asG7 .index_qrcodeWrap_3yC-q{width:2.16rem;height:2.16rem;display:flex;justify-content:center;align-items:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.index_content_1asG7 .index_qrcodeWrap_3yC-q .index_qrcode_1XjEz,.index_content_1asG7 .index_qrcodeWrap_3yC-q .index_qrcode_1XjEz img{width:1.81333rem;height:1.81333rem}.index_content_1asG7 .index_qrcodeWrap_3yC-q .index_qrcode_1XjEz canvas{width:1.81333rem!important;height:1.81333rem!important}.index_content_1asG7 .index_ownerWrap_1Nxny{height:.8rem;display:flex;justify-content:space-between}.index_content_1asG7 .index_ownerWrap_1Nxny .index_owner_2B8UU{align-self:flex-end;height:.64rem;font-size:.32rem;font-weight:500;color:#fff;line-height:.64rem}.index_content_1asG7 .index_ownerWrap_1Nxny .index_logo_9ME9S{align-self:flex-start;width:1.73333rem;height:.61333rem}.index_canvas_1aQJ2{width:8.48rem;margin:.26667rem auto 0;box-sizing:border-box;background:#fff;display:block}", ""]);
// Exports
exports.locals = {
	"head": "index_head_Sp1m2",
	"content": "index_content_1asG7",
	"banner": "index_banner_2ll9U",
	"title": "index_title_3KQQF",
	"name": "index_name_2sqjm",
	"line": "index_line_22ZOy",
	"qrcodeWrap": "index_qrcodeWrap_3yC-q",
	"qrcode": "index_qrcode_1XjEz",
	"ownerWrap": "index_ownerWrap_1Nxny",
	"owner": "index_owner_2B8UU",
	"logo": "index_logo_9ME9S",
	"canvas": "index_canvas_1aQJ2"
};
module.exports = exports;
